import React, { useEffect, useState, useCallback } from "react";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";

import CustomButton from "../../components/custombutton/CustomButton";
import { loadStripe } from "@stripe/stripe-js";
import AddBalanceModal from "./AddBalance";
import { Elements } from "@stripe/react-stripe-js";
import { useGetBalanceForWalletQuery, useGetPaymentSheetForWalletMutation, useSendCapturePaymentIdMutation, useSendPaymentStripeIdMutation, useGetCustomerTransactionsQuery } from "../../store/api";
import { useSelector } from "react-redux";
import { StripeWalletPayment } from "../../components/custommodel/StripeWalletPayment";
import toast from "react-hot-toast";
import TransactionCard from "./TransactionCard";

const WalletPage = () => {
  const [showModal, setShowModal] = useState(false);
  const userId = useSelector(state => state.auth?.user?.user?.id);
  //user from redux 
  const user = useSelector(state => state.auth?.user?.user);
  const customerId = user.paymentDetails?.stripeCustomerId;
  const [loadingStripe, setLoadingStripe] = useState(false)
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const stripePromise = loadStripe('pk_test_51NZyGNHp2vpsqcNCm4hUf09orJabuIC4jR2ofD5QdOvpL4YigxUi3Qr2pZdc61eWB50yWJfjA5UxQwy61Ey4El4p00bVPreNtp');
  const [clientSecret, setClientSecret] = useState(null);
  const [getPaymentSheet, { isLoading, isError }] = useGetPaymentSheetForWalletMutation()
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentDoc, setPaymentDoc] = useState(null);
  const [captureDoc, setCaptureDoc] = useState(null);
  const { data: balanceData, isLoading: isLoadingBalance, isError: isErrorBalance, isSuccess: isSuccessBalance, refetch: refetchBalance } = useGetBalanceForWalletQuery(userId)

  const { data: transactionData, isLoading: isLoadingTransaction, isError: isErrorTransaction, isSuccess: isSuccessTransaction, refetch: refetchTransaction } = useGetCustomerTransactionsQuery(customerId)

  console.log("TRANSACTION DATA", transactionData)



  const openPaymentModal = () => {
    setShowPaymentModal(true);
  };
  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };
  const [
    sendCapture, { isLoading: captureLoading }
  ] = useSendCapturePaymentIdMutation();
  const [sendStripeId, { isLoading: stripeLOading }] = useSendPaymentStripeIdMutation();

  useEffect(() => {
    const queryString = window.location.search;


    const amountmatch = queryString.match(/amount=([^&]*)/);
    const stripeIdmatch = queryString.match(/stripeId=([^&]*)/);
    const customerIdmatch = queryString.match(/customerId=([^&]*)/);
    const redStatus = queryString.match(/redirect_status=([^&]*)/);


    const extractedamount = amountmatch ? amountmatch[1] : null;
    const extractedstripeId = stripeIdmatch ? stripeIdmatch[1] : null;
    const extractedcustomerId = customerIdmatch ? customerIdmatch[1] : null;

    const extractedRedirectStatus = redStatus ? redStatus[1] : null;
    window.history.replaceState(null, '', window.location.pathname);

    // setCode(extractedCode);


    handleQuery(extractedamount, extractedstripeId, extractedcustomerId, extractedRedirectStatus);
  }, []);
  const handleQuery = useCallback(async (extractedamount, extractedstripeId, extractedcustomerId, extractedRedirectStatus) => {
    try {

      if (extractedRedirectStatus === 'succeeded' && extractedamount && extractedstripeId && extractedcustomerId && !stripeLOading && !captureLoading) {
        const { data } = await sendStripeId(extractedstripeId);
        // console.log("DDDDDDDDDD-->",data)
        // const {type} = data;

        const vts = {
          customerId: extractedcustomerId,
          paymentMethod: data?.type,
          amount: extractedamount,
        }
        const r = await sendCapture(vts)
        toast.success(`Adding balance to your wallet!`);
        refetchBalance(userId)


      }

    } catch (error) {
      console.log("error :", error.toString());
    } finally {
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [userId, captureLoading, stripeLOading]);
  const proceedToPayment = useCallback(
    async (ammount) => {
      setLoadingStripe(true)
      const ps = {
        userId,
        amount: ammount,
      }
      const paymentSh = await getPaymentSheet(ps)
      console.log("PAYMENT SHEEE", paymentSh)
      if (paymentSh?.data) {
        setClientSecret(paymentSh?.data?.paymentIntent);
        setPaymentDoc(paymentSh?.data?.paymentIntentId);
        const capture = {
          paymentIntentId: paymentSh?.data?.paymentIntentId,
          amount: ammount,
          customerId: paymentSh?.data?.customer
        };
        setCaptureDoc(capture);
        openPaymentModal();
      }
    },
    [userId, loadingStripe, clientSecret, paymentDoc, setCaptureDoc],
  );

  return (

    <>


      <main id="main">
        <section className="wallet_sec1">
          <Container>
            <h1>Wallet</h1>
            <Card className="balance-card">
              <Card.Body
                className="balance-card-body"
                style={{ height: "100px" }}
              >
                <div className="bal">
                  <h3> Balance</h3>
                  <p>{isSuccessBalance ? balanceData?.balance?.balance / 100 : '0.00'} €</p>
                </div>
                <div>
                  <a href="#">
                    <i className="fas fa-chevron-right"></i>
                  </a>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </section>
        <div className="adddetails">
          <Container>
            <CustomButton
              label={"Add balance"}
              color={"#333333"}
              textcolor={"white"}
              // backgroundColor={"#333333"}
              height={"50px"}
              width="100%"
              // margin={'auto'}
              onClick={handleShow}  // Open the modal on click
            />
          </Container>
        </div>

        {/* Transaction History Section */}
        <section className="transaction-history mb-5">
          <Container>
            <h2>Transaction History</h2>
            {isLoadingTransaction && <p>Loading transactions...</p>}
            {isErrorTransaction && <p>Failed to load transactions.</p>}
            {isSuccessTransaction && transactionData.length === 0 && <p>No transactions available.</p>}

            {isSuccessTransaction && transactionData.map((transaction) => (
              <TransactionCard key={transaction.id} transaction={transaction} />
            ))}
          </Container>
        </section>


        {stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <div className="adddetails">

              <StripeWalletPayment
                show={showPaymentModal}
                handleClose={closePaymentModal}
                paymentDoc={captureDoc}
                userId={userId}
                refetch={refetchBalance}
              />

            </div>
          </Elements>)}
        <AddBalanceModal show={showModal} handleClose={handleClose} proceedToPayment={proceedToPayment} />
      </main>

    </>

  );
};

export default React.memo(WalletPage);
