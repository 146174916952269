import ResetPassword from "../pages/resetPassword/ResetPassword";
import UserPrediction from "../pages/userPrediction/UserPrediction";

const RouteNames = {
  Default: {
    route: "/",
  },
  Home: {
    route: "/home",
  },
  Login: {
    route: "/login",
  },
  ForgetPassword: {
    route: "/forget-password",
  },
  ResetPassword: {
    route: "/reset-password",
  },
  EmailVerification: {
    route: "/verify-email",
  },
  PhoneVerification: {
    route: "/verify-phone",
  },
  Authentication: {
    route: "/authentication",
  },
  Signup: {
    route: "/sign-up",
  },
  Buy: {
    route: "/buy",
  },
  BottomTab: {
    route: "/bottom-tab",
  },
  Myliberdat: {
    route: "/my-liberdat",
  },
  Wallet: {
    route: "/wallet",
  },
  MyBids: {
    route: "/myBids",
  },
  AssignUser: {
    route: "/assign-users",
  },
  Notifications: {
    route: "/notification",
  },
  Messages: {
    route: "/messages",
  },
  Datapage: {
    route: "/data-page",
  },
  PageNotFound: {
    route: "*",
  },
  Footer: {
    route: "/footer",
  },

  UpdateBids: {
    route: "/update-bids",
  },
  AcceptBidReq: {
    route: "/my-requests",
  },
  PaymentForm: {
    route: "/paymentForm",
  },
  PrivacyPolicy: {
    route: "/privacy-statement",
  },
  PrivacyP: {
    route: "/privacy-policy",
  },
  TermsAndConditions: {
    route: "/terms-and-conditions",
  },
  About: {
    route: "/about",
  },
  Survey: {
    route: "/survey",
  },
  SurveyViewer: {
    route: "/survey-viewer",
  },
  CSV: {
    route: "/download-csv",
  },
  SurveyCompleted: {
    route: "/fill-survey",
  },
  MobileApp: {
    route: "/mobile-app",
  },
  HowItWorks: {
    route: "/how-it-works",
  },
  GeneralInfo: {
    route: "/general-info",
  },
  BuyerInfo: {
    route: "/buyer-info",
  },
  ProfileInfo: {
    route: "/profile-info",
  },

  EmailVerification: {
    route: `/verify-email`,
  },

  FAQs: {
    route: `/FAQs`,
  },
  DeleteAccount: {
    route: "/delete-account",
  },
  DeleteByEmail: {
    route: "/delete-by-email",
  },
  Team: {
    route: "/team",
  },
  ConfirmDelete: {
    route: "/confirm-delete",
  },
  AuthSucces: {
    route: "/auth-success",
  },
  UserPrediction: {
    route: "/user-prediction",
  },
};

export default RouteNames;
