import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { dataForGeneralTherapy } from "./TherapyData";
import "./ProfileInfo.scss";

const AddTherapyModal = ({ show, handleClose, addTherapy, existingTherapies,selectedDiagnosis }) => {
    const [selectedTherapies, setSelectedTherapies] = useState([]);
    const [step, setStep] = useState(1);
    const [dates, setDates] = useState({});


    // console.log('selectedTherapies', selectedTherapies);



    // Toggling states for expanding/collapsing dropdowns
    const [isCurrentOpen, setIsCurrentOpen] = useState(false);
    const [isPreviousOpen, setIsPreviousOpen] = useState(false);
    const [expandedItems, setExpandedItems] = useState({});


    // console.log('existingTherapies', existingTherapies);

    // Function to determine if the therapy is already selected
    const isTherapySelected = (therapy) => {
        return existingTherapies.some(existing => existing.displayName === therapy.displayName);
    };


    const handleModalClose = () => {
        setStep(1);
        setSelectedTherapies([]);
        setDates({});
        handleClose();
    };

    const handleTherapyChange = (therapy) => {
        if (selectedTherapies.some(t => t.value === therapy.value)) {
            setSelectedTherapies((prev) => prev.filter((t) => t.value !== therapy.value));
        } else {
            setSelectedTherapies((prev) => [...prev, therapy]);
        }
    };


    const toggleExpansion = (value) => {
        setExpandedItems((prev) => ({
            ...prev,
            [value]: !prev[value],
        }));
    };

    const handleNext = () => {
        //Only change modal is unique therapies are selected
        if (selectedTherapies.filter(selected =>
            !existingTherapies.some(existing => existing.value === selected.value)
        ).length > 0) {
            setStep(2);
        }

    };

    const handleConfirm = () => {
        // Check if the form is valid before proceeding
        const isFormValid = selectedTherapies.every((therapy) => {
            const { startDate, endDate } = dates[therapy.value] || {};
            const isPrevious = therapy.displayName.includes("Previous");
            if (isPrevious) {
                return startDate && endDate;
            }
            return startDate;
        });

        if (!isFormValid) {
            return;
        }

        const newTherapies = selectedTherapies.map((therapy) => {
            const startDateValue = dates[therapy.value]?.startDate;
            const endDateValue = therapy.displayName.includes('Current') ? true : (dates[therapy.value]?.endDate || null);

            return {
                displayName: therapy.displayName,
                startDate: startDateValue,
                endDate: endDateValue === true ? true : endDateValue,
                bidValue: therapy.bidValue,
                value: therapy.value,
            };
        });

        addTherapy(newTherapies); // Add therapy function
        handleModalClose(); // Close modal
    };


    return (
        <Modal show={show} onHide={handleModalClose}>
            <Modal.Header style={{ border: 'none' }} closeButton>
                <Modal.Title>{step === 1 ? "Therapies" : "Time Period"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {step === 1 ? (
                    <>
                        {/* No therapies option */}
                        <Form.Check
                            type="checkbox"
                            label="No therapies"
                            style={{ fontWeight: 'bold', marginLeft: '0.5rem', marginBottom: '1rem' }}
                            checked={selectedTherapies.some(therapy => therapy.value === 'Therapies_No therapies')}
                            onChange={() => {
                                if (!selectedTherapies.some(therapy => therapy.value === 'Therapies_No therapies')) {
                                    setSelectedTherapies([{ value: 'Therapies_No therapies', displayName: 'No therapies' }]);
                                    setStep(2);
                                }
                            }}
                            disabled={selectedTherapies.some(therapy => therapy.value === 'Therapies_No therapies')}
                        />
                        <hr style={{ margin: '10px 0' }} />


                        {/* Current Therapies Dropdown */}
                        <Form.Group style={{ padding: '0.5rem' }}>
                            <Form.Label onClick={() => setIsCurrentOpen((prev) => !prev)} style={{ cursor: 'pointer', fontWeight: 'bold', marginTop: '1rem', marginLeft: '0.5rem' }}>
                                {isCurrentOpen ? "▼" : "►"}  Current
                            </Form.Label>
                            {isCurrentOpen && (
                                <div className="therapy-dropdown">
                                    {dataForGeneralTherapy
                                        .find(category => category.value === "Therapies_Current")
                                        ?.data.map((therapyCategory) => (
                                            <div key={therapyCategory.value}>
                                                {/* Handle nested dropdown */}
                                                <Form.Label onClick={() => toggleExpansion(therapyCategory.value)} style={{ cursor: 'pointer', paddingLeft: '20px' }}>
                                                    {expandedItems[therapyCategory.value] ? "▼" : "►"}  {therapyCategory.label}
                                                </Form.Label>
                                                {expandedItems[therapyCategory.value] && (
                                                    <div style={{ paddingLeft: '40px' }}>
                                                        {/* Loop through the nested therapies (e.g., Tablets inside Pharmaceutical) */}
                                                        {therapyCategory.data.map((subCategory) => (
                                                            <div key={subCategory.value}>
                                                                <Form.Label onClick={() => toggleExpansion(subCategory.value)} style={{ cursor: 'pointer', paddingLeft: '20px' }}>
                                                                    {expandedItems[subCategory.value] ? "▼" : "►"}  {subCategory.label}
                                                                </Form.Label>

                                                                {expandedItems[subCategory.value] && (
                                                                    <div style={{ paddingLeft: '40px' }}>
                                                                        {/* Check if subCategory has further nested data */}
                                                                        {subCategory.data.length > 0 ? (
                                                                            subCategory.data.map((therapy) => (
                                                                                therapy.data.length === 0 && (!therapy?.belongsTo || selectedDiagnosis?.some(i => i?.value?.includes(therapy?.belongsTo)))? (

                                                                                    <Form.Check
                                                                                        key={therapy.value}
                                                                                        type="checkbox"
                                                                                        label={therapy.label}
                                                                                        checked={selectedTherapies.includes(therapy) || isTherapySelected(therapy)}
                                                                                        onChange={() => handleTherapyChange(therapy)}
                                                                                        disabled={isTherapySelected(therapy)}
                                                                                    />
                                                                                ) : null
                                                                            ))
                                                                        ) : (
                                                                            <Form.Check
                                                                                key={subCategory.value}
                                                                                type="checkbox"
                                                                                label={subCategory.label}
                                                                                checked={selectedTherapies.includes(subCategory) || isTherapySelected(subCategory)}
                                                                                onChange={() => handleTherapyChange(subCategory)}
                                                                                disabled={isTherapySelected(subCategory)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}

                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            )}
                        </Form.Group>
                        <hr style={{ margin: '10px 0' }} />


                        {/* Previous Therapies Dropdown */}
                        <Form.Group style={{ padding: '0.5rem' }}>
                            <Form.Label onClick={() => setIsPreviousOpen((prev) => !prev)} style={{ cursor: 'pointer', fontWeight: 'bold', marginLeft: '0.5rem' }}>
                                {isPreviousOpen ? "▼" : "►"}  Previous
                            </Form.Label>
                            {isPreviousOpen && (
                                <div className="therapy-dropdown">
                                    {dataForGeneralTherapy
                                        .find(category => category.value === "Therapies_Previous")
                                        ?.data.map((therapyCategory) => (
                                            <div key={therapyCategory.value}>
                                                <Form.Label onClick={() => toggleExpansion(therapyCategory.value)} style={{ cursor: 'pointer', paddingLeft: '20px' }}>
                                                    {expandedItems[therapyCategory.value] ? "▼" : "►"}  {therapyCategory.label}
                                                </Form.Label>
                                                {expandedItems[therapyCategory.value] && (
                                                    <div style={{ paddingLeft: '40px' }}>
                                                        {/* Loop through the nested therapies */}
                                                        {therapyCategory.data.map((subCategory) => (
                                                            <div key={subCategory.value}>
                                                                <Form.Label onClick={() => toggleExpansion(subCategory.value)} style={{ cursor: 'pointer', paddingLeft: '20px' }}>
                                                                    {expandedItems[subCategory.value] ? "▼" : "►"}  {subCategory.label}
                                                                </Form.Label>

                                                                {expandedItems[subCategory.value] && (
                                                                    <div style={{ paddingLeft: '40px' }}>
                                                                        {/* Check if subCategory has further nested data */}
                                                                        {subCategory.data.length > 0 ? (
                                                                            subCategory.data.map((therapy) => (
                                                                                therapy.data.length === 0 && (!therapy?.belongsTo || selectedDiagnosis?.some(i => i?.value?.includes(therapy?.belongsTo)))? ( // If no further nesting, show as a checkbox
                                                                                    <Form.Check
                                                                                        key={therapy.value}
                                                                                        type="checkbox"
                                                                                        label={therapy.label}
                                                                                        checked={selectedTherapies.includes(therapy) || isTherapySelected(therapy)}
                                                                                        onChange={() => handleTherapyChange(therapy)}
                                                                                        disabled={isTherapySelected(therapy)}
                                                                                    />
                                                                                ) : null
                                                                            ))
                                                                        ) : (
                                                                            <Form.Check
                                                                                key={subCategory.value}
                                                                                type="checkbox"
                                                                                label={subCategory.label}
                                                                                checked={selectedTherapies.includes(subCategory) || isTherapySelected(subCategory)}
                                                                                onChange={() => handleTherapyChange(subCategory)}
                                                                                disabled={isTherapySelected(subCategory)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            )}
                        </Form.Group>
                    </>
                ) : (
                    <>
                        {/* Step 2: Set dates for selected therapies */}
                        {selectedTherapies.map((therapy) => (
                            <div key={therapy.value} className="diagnosis-section" style={{ marginTop: '1rem' }}>
                                <h5>{therapy.displayName}</h5>
                                <Form.Group>
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={dates[therapy.value]?.startDate || ''}
                                        onChange={(e) =>
                                            setDates((prev) => ({
                                                ...prev,
                                                [therapy.value]: { ...prev[therapy.value], startDate: e.target.value },
                                            }))
                                        }
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>End Date</Form.Label>
                                    {therapy.displayName.includes('Current')?
                                    <Form.Control
                                    type="text"
                                    value="present"
                                    style={{ marginBottom: '1rem' }}
                                    readOnly
                                  />
                                    :
                                    <Form.Control
                                        type="date"
                                        // if includes Current in therapy name then End Date is true and disable the input
                                        disabled={therapy.displayName.includes('Current')}
                                        value={(therapy.displayName.includes('Current') ? true : dates[therapy.value]?.endDate)}
                                        onChange={(e) =>
                                            setDates((prev) => ({
                                                ...prev,
                                                [therapy.value]: { ...prev[therapy.value], endDate: e.target.value },
                                            }))
                                        }
                                    />}
                                     
                                </Form.Group>
                            </div>
                        ))}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer style={{ border: 'none', margin: '1.4rem' }}>
                {step === 1 ? (
                    <span
                        className="confirmBtn"
                        onClick={handleNext}
                        disabled={selectedTherapies.filter(selected =>
                            !existingTherapies.some(existing => existing.value === selected.value)
                        ).length === 0}
                        style={{
                            color: selectedTherapies.filter(selected =>
                                !existingTherapies.some(existing => existing.value === selected.value)
                            ).length === 0 ? '#fff' : '#fff',
                            backgroundColor: selectedTherapies.filter(selected =>
                                !existingTherapies.some(existing => existing.value === selected.value)
                            ).length === 0 ? '#ccc' : '#5b99a6',
                            cursor: selectedTherapies.filter(selected =>
                                !existingTherapies.some(existing => existing.value === selected.value)
                            ).length === 0 ? 'not-allowed' : 'pointer',
                        }}
                    >
                        Next
                    </span>

                ) : (
                    <span
                        className="confirmBtn"
                        onClick={handleConfirm}
                        disabled={selectedTherapies.some((therapy) => {
                            const { startDate, endDate } = dates[therapy.value] || {};
                            const isPrevious = therapy.displayName.includes("Previous");

                            if (isPrevious) {
                                return !startDate || !endDate;
                            }

                            return !startDate;
                        })}
                        style={{
                            color: selectedTherapies.some((therapy) => {
                                const { startDate, endDate } = dates[therapy.value] || {};
                                const isPrevious = therapy.displayName.includes("Previous");

                                if (isPrevious) {
                                    return !startDate || !endDate;
                                }

                                return !startDate;
                            })
                                ? "#fff"
                                : "#fff",
                            backgroundColor: selectedTherapies.some((therapy) => {
                                const { startDate, endDate } = dates[therapy.value] || {};
                                const isPrevious = therapy.displayName.includes("Previous");

                                if (isPrevious) {
                                    return !startDate || !endDate;
                                }

                                return !startDate;
                            })
                                ? "#ccc"
                                : "#5b99a6",
                            cursor: selectedTherapies.some((therapy) => {
                                const { startDate, endDate } = dates[therapy.value] || {};
                                const isPrevious = therapy.displayName.includes("Previous");

                                if (isPrevious) {
                                    return !startDate || !endDate;
                                }

                                return !startDate;
                            })
                                ? "not-allowed"
                                : "pointer",
                        }}
                    >
                        Confirm
                    </span>


                )}
            </Modal.Footer>
        </Modal>
    );
};

export default AddTherapyModal;
