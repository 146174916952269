import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useConfigureMutation } from '../../store/api';
import "./style.scss";
import CustomButton from '../../components/custombutton/CustomButton';
import { Loader } from '../../components/Loader';

const StepsFlights = ({ isOpen, onRequestClose, selectedOption, bid, checkboxValues,getData }) => {
  const [selectedTherapy, setSelectedTherapy] = useState(null);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null);
  const [timeSelection, setTimeSelection] = useState([]);
  const [Configure, { data, isSuccess, isError, isLoading }] = useConfigureMutation();

  // console.log('STEPS FLIGHTS', selectedOption, bid, checkboxValues);

  const onSubmit = async (values, { resetForm }) => {
    const vts={
      configType: 'Relative',
      name:selectedOption,
      baseTime: checkboxValues,
      duration: {
        interval: {
          unit:values?.interval,
          count:Number(values.numberOfdays),
          ...(timeSelection?.includes('After') &&{
            afterUnit:values?.interval,
            afterCount:Number(values.afterNumberOfdays),
          })
          
        },    
      },
    }
    if (vts?.baseTime === 'Date of Therapy start') {
      vts.duration.timeOfTherapy = timeSelection;
      vts.therapyName=selectedTherapy;
    }
    else if (vts?.baseTime === 'Date of Diagnosis') {
      vts.duration.timeOfDiagnosis = timeSelection;
      vts.diagnosisName = selectedDiagnosis;
    }
    // console.log('vts', vts);
    Configure({ vts, id: bid?._id });
    setTimeSelection([]);
    setSelectedTherapy('')
    setSelectedDiagnosis('')
    resetForm();
  };

  const initialValues = {
    interval: 'Days',
    numberOfdays: '',
    afterNumberOfdays: '',
  };

  const validationSchema = yup.object().shape({
    numberOfdays: timeSelection?.includes('Before') && yup.string().required('Duration number is Required for before'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success('Configuration Successful');
      getData();
      setTimeout(() => { onRequestClose() }, 500);
    } else if (isError) {
      toast.error('Configuration Unsuccessful');
    }
  }, [isSuccess]);

  const handleTherapyClick = (therapy) => {
    setSelectedTherapy(therapy);
  };
  const handleDiagnosisClick=(diagnosis) => {
    setSelectedDiagnosis(diagnosis);
  };
  const handleTimeSelection = (e) => {
    const { name, checked } = e.target;
    setTimeSelection((prev) => 
      checked ? [...prev, name] : prev.filter((item) => item !== name)
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        },
        content: {
          width: "fit-content",
          margin: "auto",
          height: "fit-content",
          padding: "20px",
          borderRadius:'10px'
        },
      }}
    >
      <h2>
        {checkboxValues}<h5> Relative</h5>
      </h2>
      
      {(bid?.dataItem?.specifiedValues?.some(i => i?.value?.includes('Current')) || bid?.dataItem?.specifiedValues?.some(i => i?.value?.includes('Previous'))) && checkboxValues === 'Date of Therapy start' ? (
        <div className="allTherapies">{
          bid?.dataItem?.specifiedValues?.map((item, index) => {
            return (
              item?.value?.includes('Therapies') ?
                <div
                  key={index}
                  className={selectedTherapy === item.value ? 'SelectedsingleTherapy' : "singleTherapy"}
                  onClick={() => handleTherapyClick(item.value)}
                >
                  {item.displayName}
                </div> : null
            );
          })}
        </div>) : checkboxValues === 'Date of Diagnosis'?(<div className="allTherapies">{
          bid?.diagnosis?.map((item, index) => {
            return (
              
                <div
                  key={index}
                  className={selectedDiagnosis === item ? 'SelectedsingleTherapy' : "singleTherapy"}
                  onClick={() => handleDiagnosisClick(item)}
                >
                  {item}
                </div> 
            );
          })}
        </div>)
        :null}
      
      <Form onSubmit={formik.handleSubmit} className="">
        <Row>
        {(checkboxValues==='Date of Therapy start' && selectedTherapy) || (checkboxValues==='Date of Diagnosis' && selectedDiagnosis)?
          <Col lg={12} xs={12}>
            <Form.Label className='sub-heading'>Time</Form.Label>
            <Form.Group style={{ display: 'flex', gap: '22px', padding: '5px' }}>
              <Form.Check
                type="checkbox"
                id="Before"
                name="Before"
                label="Before"
                checked={timeSelection.includes('Before')}
                onChange={handleTimeSelection}
                onBlur={formik.handleBlur}
              />
               {checkboxValues==='Date of Therapy start' &&
              <Form.Check
                type="checkbox"
                id="During"
                name="During"
                label="During"
                checked={timeSelection.includes('During')}
                onChange={handleTimeSelection}
                onBlur={formik.handleBlur}
              />}
              {selectedTherapy?.includes('Previous') || checkboxValues==='Date of Diagnosis'&&
              <Form.Check
                type="checkbox"
                id="After"
                name="After"
                label="After"
                checked={timeSelection.includes('After')}
                onChange={handleTimeSelection}
                onBlur={formik.handleBlur}
              />}
            </Form.Group>
          </Col>:null}
          {timeSelection?.includes('Before') ||
          (checkboxValues != 'Date of Therapy start' && checkboxValues != 'Date of Diagnosis')
          ?
          <Row>
            {checkboxValues==='Date of Therapy start' || checkboxValues==='Date of Diagnosis' ?<Form.Label className='sub-heading'> Before</Form.Label>:null}
            

         
          <Col lg={6} xs={12}>
            <Form.Group className="form-group mb-3">
              <Form.Label>Number of {formik.values.interval}</Form.Label>
              <Form.Control
                id="numberOfdays"
                name="numberOfdays"
                type="number"
                value={formik.values.numberOfdays}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.numberOfdays && formik.errors.numberOfdays ? (
                <div className="error">{formik.errors.numberOfdays}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col lg={6} xs={12}>
            <Form.Group className="form-group mb-3">
              <Form.Label>Interval</Form.Label>
              <Form.Select
                name="interval"
                id="interval"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.interval}
              >
                <option value="DAYS">Days</option>
                <option value="WEEKS">Weeks</option>
                <option value="MONTHS">Months</option>
              </Form.Select>
              {formik.touched.interval && formik.errors.interval ? (
                <div className="error">{formik.errors.interval}</div>
              ) : null}
            </Form.Group>
          </Col>
          </Row>:null}
          {timeSelection?.includes('After')?
          <Row>
            <Form.Label className='sub-heading'> After</Form.Label>

         
          <Col lg={6} xs={12}>
            <Form.Group className="form-group mb-3">
              <Form.Label>Number of {formik.values.interval}</Form.Label>
              <Form.Control
                id="afterNumberOfdays"
                name="afterNumberOfdays"
                type="number"
                value={formik.values.afterNumberOfdays}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.afterNumberOfdays && formik.errors.afterNumberOfdays ? (
                <div className="error">{formik.errors.afterNumberOfdays}</div>
              ) : null}
            </Form.Group>
          </Col>
          <Col lg={6} xs={12}>
            <Form.Group className="form-group mb-3">
              <Form.Label>Interval</Form.Label>
              <Form.Select
                name="interval"
                id="interval"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.interval}
              >
                <option value="DAYS">Days</option>
                <option value="WEEKS">Weeks</option>
                <option value="MONTHS">Months</option>
              </Form.Select>
              {formik.touched.interval && formik.errors.interval ? (
                <div className="error">{formik.errors.interval}</div>
              ) : null}
            </Form.Group>
          </Col>
          </Row>:null}
          {isLoading ? (
            <div className="loader-container">
              <Loader color="black" />
            </div>
          ) : (
            <Col xs={12}>
              <CustomButton
                className="Btn1"
                label={'Submit'}
                color={'#333333'}
                textcolor={'white'}
                width={'100%'}
                backgroundColor={'#333333'}
                type="submit"
              />
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default StepsFlights;
