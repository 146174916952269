import React from 'react';
import { Images } from '../../assets/images';
import './style.scss';
import { Card, Col, Container, Row, CardImg } from 'react-bootstrap';

export const HowItWorks = () => {
  const cards1 = [
    {
      image: Images.helooo,
      title: <strong>1. Sign up</strong>,
      body: (
        <p>
          {' '}

          Download the free LiberDat app and sign up. 


        </p>
      ),
    },
    {
     
      image: Images.signupp,
      title: <strong>2. Manage</strong>,
      body: (
        <p>
          {' '}

         Choose which data you want to share and who you want to share it with.


        </p>
      ),
    },
    {

      
      image:  Images.engage,
      title: <strong>3. Share</strong>,
      body: <p> Share your data to gain valuable insights.

      </p>,
    },
  ];
  const cards2 = [
    {
      image: Images.signup2,
      title: <strong>1. Sign up</strong>,
      body: (
        <p>
          {' '}
          Download the free LiberDat application and sign up. Specify the source and type of data you are interested in.
        </p>
      ),
    },
    {
      image: Images.request,
      title: <strong>2. Engage</strong>,
      body: (
        <p>
          {' '}
          Engage with the community to collaborate and develop new hypotheses.
        </p>
      ),
    },
    {
      image: Images.access,
      title: <strong>3. Access</strong>,
      body: (
        <p>
          {' '}
          Gain access to an unlimited pool of data without restrictions.
        </p>
      ),
    },
  ];
  return (
    <div className="main-container">
      <img className="coverimage" src={Images.howitworks} alt="sadsa" />
      <div class="text-overlay">

      Effortlessly manage and share your data to improve your life and that of others. Anonymously and securely.

      </div>

      <div className="info">
        <Container>
          <div className="sharedata">
            <h1>Easily Share Data</h1>
          </div>
          <Card.Body>
            <Row>
              {cards1.map((card) => (
                <Col xs={12} md={4} lg={4}>
                  <Card style={{ border: 'none', boxShadow: 'none' }}>
                    <CardImg className="card-img-custom" variant="top" src={card.image} alt="Card image" />
                    <Card.Header style={{ fontSize: "larger"}}>{card.title}</Card.Header>
                    <Card.Body>
                      <Card.Text style={{color: "rgba(117,117,117)", fontSize: "larger"}}>{card.body}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Container>

        <hr/>

      
        <Container>
        <div className="buydata">
          <h1>Easily Request Data</h1>
        </div>

          <Card.Body>
            <Row>
              {cards2.map((card) => (
                <Col xs={12} md={4} lg={4}>
                  <Card style={{ border: 'none', boxShadow: 'none' }}>
                    <CardImg className="card-img-custom" variant="top" src={card.image} alt="Card image" />
                    <Card.Header style={{ fontSize: "larger"}}>{card.title}</Card.Header>
                    <Card.Body>
                      <Card.Text  style={{color: "rgba(117,117,117)", fontSize: "larger"}}>{card.body}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Container>
      </div>
    </div>
  );
};