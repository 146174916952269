import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetPredictedDataofUserQuery, useUpdateSellerGraphNotificationMutation, useUpdateThresholdMutation } from "../../store/api";
import ReactApexChart from "react-apexcharts";
import styles from "./UserPrediction.module.scss";
import CustomButton from "../../components/custombutton/CustomButton";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AiFillAlert } from "react-icons/ai";
import ThresholdModal from "../../components/thresholdmodal/ThresholdModal";
import "./style.scss";

const UserPrediction = () => {
  const location = useLocation();
  const dataFromProbs=location.state?.user;
  const loggedInUser = useSelector((state) => state?.auth?.user?.user);
  const user = dataFromProbs?dataFromProbs:loggedInUser;

  // console.log("User SELLER: ", user);
  // const loggedInUser = useSelector((state) => state.auth?.user?.user);
  const [predictionType, setPredictionType] = useState("Predicted");
  const [dataType, setDataType] = useState("Steps/Day");
  const [thresholdValue, setThresoldValue] = useState('')
  const [showThreshold, setShowThreshold] = useState(false);
  const [notificationSent, setNotificationSent] = useState(false);
  const [notifySeller, { data: dataNotify, isSuccess, isError: isErrorNotify, error }] = useUpdateSellerGraphNotificationMutation();

  const [updateThresold, { isSuccess: thresholdIsSuccess, isError: thresholdIsError }] = useUpdateThresholdMutation()

  // sending the threshold value and Seller id to the backend
  const handleThresholdSubmit = async () => {

    const thresholdData = {
      sellerId: user?._id ? user._id : user?.id,
      threshold: thresholdValue
    }
    console.log("Threshold Data: ", thresholdData)
    await updateThresold(thresholdData)
      .unwrap()
      .then(() => {
        // Success handling
        toast.success('Threshold Update Request sent');
        setShowThreshold(false); // Close modal on success
        setNotificationSent(true);
      })
      .catch(() => {
        // Error handling
        toast.error('Failed to update threshold');
      });
    if (thresholdIsSuccess) {
      toast.success("Threshold updated successfully")
      setShowThreshold(false)
    }
    if (thresholdIsError) {
      toast.error("Failed to update threshold")
    }
  }


  const {
    data: predictedData,
    isLoading,
    isError,
  } = useGetPredictedDataofUserQuery(user?._id ? user._id : user?.id);

  console.log(dataNotify, isSuccess);

  useEffect(() => {

    if (isSuccess) {
      toast.success("Request Sent Successfully")
    }
    if (isErrorNotify) {
      toast.error("Failed to send request")
    }
  }, [isSuccess, isErrorNotify])


  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-line",
        type: "line",
      },
      xaxis: {
        categories: [],
        min: 0,
        max: 365,
        tickAmount: 10,
      },
      yaxis: {
        title: {
          text: "Steps/Day",
        },
        min: 0,
        max: 15000,
        tickAmount: 5,
      },
    },
    series: [
      {
        name: "Historical",
        data: [],
      },
      {
        name: "Predicted",
        data: [],
      },
    ],
  });

  // console.log("Chart data:", {
  //   options: chartData.options,
  //   series: chartData.series,
  // });

  const calculateTrendLine = (data) => {
    const n = data.length;
    if (n === 0) return [];
    const sumX = (n * (n - 1)) / 2;
    const sumY = data.reduce((acc, y) => acc + y, 0);
    const sumXY = data.reduce((acc, y, x) => acc + x * y, 0);
    const sumX2 = (n * (n - 1) * (2 * n - 1)) / 6;

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    return data.map((_, x) => slope * x + intercept);
  };

  const processData = (data, key) => {
    return data
      .map(item => item ? parseFloat(item[key]) : 0)
      .filter(value => !isNaN(value));
  };


  console.log("Predicted Data: ", predictedData);

  useEffect(() => {
    if (predictedData && dataType === "Steps/Day") {
      const historicalData = predictedData.historicalData || [];
      const predictions = predictedData.predictions || [];
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];



      const convertMonthToFloat = (year, month) => {
        return (parseFloat(year) + (parseFloat(month) / 12)).toFixed(2);
      };

      const convertFloatToYearMonth = (floatValue) => {
        const year = Math.floor(floatValue);
        let month = Math.round((floatValue - year) * 12);
        if (month === 0) {
          month = 12;
        }
        return `${year}`;
      };

      const firstNonZeroIndex = historicalData.findIndex(item => item && item["StepsCount"] !== 0);
      const startYear = firstNonZeroIndex !== -1 ? historicalData[firstNonZeroIndex]?.year : null;
      const filteredHistoricalData = historicalData.filter(
        (item) => item && item.year >= startYear
      );

      const historicalWeeks = filteredHistoricalData.map(
        (item) => convertMonthToFloat(item.year, item.month)
      );
      const predictedDays = predictions.map((_, index) => index );

      const historicalValues = processData(filteredHistoricalData, "StepsCount");
      const predictedValues = processData(predictions, "StepsCount");

      const firstNonZeroHistoricalIndex = historicalWeeks.findIndex(value => value !== 0);
      const firstNonZeroPredictedIndex = predictedValues.findIndex(value => value !== 0);

      const historicStepCountYears = historicalWeeks.slice(firstNonZeroHistoricalIndex);
      const onlyYears = historicStepCountYears.map((data) => Math.floor(data));
      const uniqueYears = [...new Set(onlyYears)];

      const historicalTrendLine = calculateTrendLine(historicalValues.slice(firstNonZeroHistoricalIndex));
      const predictedTrendLine = calculateTrendLine(predictedValues.slice(firstNonZeroPredictedIndex));

      setChartData({
        options: {
          chart: {
            id: "basic-line",
            type: 'line',
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            title: {
              text: predictionType === 'Predicted' ? "Days" : 'Years',
            },

            labels: {
              formatter: function (val) {
                return convertFloatToYearMonth(parseFloat(val));
              }
            },
            categories: predictionType === "Predicted"
              ? predictedDays.slice(firstNonZeroPredictedIndex)
              : historicStepCountYears,
            min: predictionType === "Predicted" ? 0 : parseFloat(historicStepCountYears[0]),
            max: predictionType === "Predicted" ? predictedDays.length : parseFloat(historicStepCountYears[historicStepCountYears.length - 1]),
            tickAmount: predictionType === "Predicted" ? 20 : uniqueYears.length - 1,
          },
          colors: ["#5b99a6", "#5b99a6"],
          yaxis: {
            title: {
              text: "Step count/Day",
            },
            min: 0,
            max: 17000,
            tickAmount: 5,
            labels: {
              formatter: (value) => value?.toFixed(0),
            },
          },
          stroke: { width: 4 },
        },
        zoom: {
          enabled: false,
        },
        series: predictionType === "Predicted" ?
          [
            {
              name: "Predicted",
              data: predictedValues.slice(firstNonZeroPredictedIndex),
            },
            {
              name: "Predicted Trend",
              data: predictedTrendLine,
              type: 'line',
              color: '#FF0000',
            },
          ] : [
            {
              name: "Historical",
              data: historicalValues.slice(firstNonZeroHistoricalIndex),
              color: '#5b99a6',
            },
            {
              name: "Historical Trend",
              data: historicalTrendLine,
              type: 'line',
              color: '#FF0000',
            },
          ],
      });
    } else if (isError) {
      const fakeCategories = Array.from(
        { length: 30 },
        (_, i) => `Day ${i + 1}`
      );
      const fakeHistorical = Array.from({ length: 30 }, () =>
        Math.floor(Math.random() * 10000)
      );
      const fakePredicted = Array.from({ length: 30 }, () =>
        Math.floor(Math.random() * 10000)
      );

      setChartData({
        options: {
          chart: {
            id: "basic-line",
            type: 'line',
          },
          xaxis: {
            categories: fakeCategories,
            min: 0,
            max: 365,
            tickAmount: 10,
          },
          yaxis: {
            title: {
              text: "Step count/Day",
            },
            min: 0,
            max: 15000,
            tickAmount: 5,
          },
        },
        series: [
          {
            name: "Historical",
            data: fakeHistorical,
          },
          {
            name: "Predicted",
            data: fakePredicted,
          },
        ],
      });
    }
  }, [predictedData, predictionType, dataType, isError]);


  useEffect(() => {
    if (predictedData && dataType === "Places visited / Day") {
      const historicalData = predictedData.historicalData || [];
      const predictions = predictedData.predictions || [];

      const convertFloatToYearMonthPlaces = (floatValue) => {
        const year = Math.floor(floatValue);
        let month = Math.round((floatValue - year) * 12);
        if (month === 0) {
          month = 12;
        }
        return `${year}`;
      };

      // Filtering historical data
      const filteredHistoricalData = historicalData.filter(
        (item) => item.PlacesVisited !== 0
      );
      const nonZeroAnalysis = historicalData.filter(item => parseFloat(item.Analysis) !== 0);
      const nonZeroPlaces = historicalData.filter(item => item.PlacesVisited);
      const nonZeroPlacesYears = nonZeroPlaces.map(item => parseFloat(item.year));
      let uniqueYears = [...new Set(nonZeroPlacesYears)];

      const placesVisitedValues = filteredHistoricalData.map(
        (item) => item.PlacesVisited
      );
      const nonNullPlaces = placesVisitedValues.filter(item => item !== null);

      if (uniqueYears.length <= 1) {
        uniqueYears = Array.from({ length: nonNullPlaces }, (_, i) => i + 1);
      }
      // console.log("nonNullPlaces", nonNullPlaces);
      // console.log("nonZeroPlacesYears", nonZeroPlacesYears);

      // Get the values and months from the filtered historical data
      const historicalMonths = filteredHistoricalData.map(
        (item) => item.month
      );

      const historicalValues = filteredHistoricalData.map(
        (item) => item.PlacesVisited
      );

      const firstNonZeroHistoricalIndex = historicalValues.findIndex(value => value !== 0);

      // Adjust the historical values and months based on the first non-zero index
      const adjustedHistoricalValues = firstNonZeroHistoricalIndex !== -1
        ? historicalValues.slice(firstNonZeroHistoricalIndex)
        : historicalValues;



      const adjustedHistoricalMonths = firstNonZeroHistoricalIndex !== -1
        ? historicalMonths.slice(firstNonZeroHistoricalIndex)
        : historicalMonths;

      // Filtering prediction data
      const filteredPredictions = predictions.filter(
        (item) => item.PlacesVisited !== 0
      );

      const predictedDays = filteredPredictions.map(
        (item, index) => index + 1
      );

      const predictedValues = filteredPredictions.map(
        (item) => item.PlacesVisited
      );


      const firstNonZeroPredictedIndex = predictedValues.findIndex(value => value !== 0);

      const adjustedPredictedValues = firstNonZeroPredictedIndex !== -1
        ? predictedValues.slice(firstNonZeroPredictedIndex)
        : Array(predictedDays.length).fill(0);

      const adjustedPredictedDays = firstNonZeroPredictedIndex !== -1
        ? predictedDays.slice(firstNonZeroPredictedIndex)
        : predictedDays;

      // Calculate trend lines
      const historicalTrendLine = calculateTrendLine(adjustedHistoricalValues);
      const predictedTrendLine = calculateTrendLine(adjustedPredictedValues);

      setChartData({
        options: {
          chart: {
            id: "basic-line",
            type: 'line',
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            title: {
              text: predictionType === 'Predicted' ? "Days" : 'Years',
            },
            labels: {
              formatter: function (val) {
                return convertFloatToYearMonthPlaces(parseFloat(val));
              }
            },
            categories:
              predictionType === "Predicted"
                ? adjustedPredictedDays
                : uniqueYears,
            min: predictionType === "Predicted" ? 0 : uniqueYears[0],
            max: predictionType === "Predicted" ? adjustedPredictedDays.length : uniqueYears[uniqueYears.length - 1],
            tickAmount: predictionType === 'Predicted' ? 25 : nonNullPlaces.length - 1,
          },
          colors: ["#5b99a6", "#5b99a6"],
          yaxis: {
            title: {
              text: dataType,
            },
            min: 0,
            max: 30,
            tickAmount: 5,
            labels: {
              formatter: function (val) {
                return convertFloatToYearMonthPlaces(parseFloat(val));
              }
            },

          },
          stroke: { width: 4 },
        },
        zoom: {
          enabled: false,
        },
        series: predictionType === "Predicted" ?
          [
            {
              name: "Predicted",
              data: adjustedPredictedValues.length > 0 ? adjustedPredictedValues : Array(adjustedPredictedDays.length).fill(0),
            },
            {
              name: "Predicted Trend",
              data: predictedTrendLine,
              type: 'line',
              color: '#FF0000',
            },
          ] : [
            {
              name: "Historical",
              //Need to Give values here
              data: nonNullPlaces.length > 0 ? nonNullPlaces : Array(nonNullPlaces).fill(0),
              color: '#5b99a6',
            },
          ],
      });
    } else if (isError) {
      const fakeCategories = Array.from(
        { length: 30 },
        (_, i) => `Day ${i + 1}`
      );
      const fakeHistorical = Array.from({ length: 30 }, () =>
        Math.floor(Math.random() * 10000)
      );
      const fakePredicted = Array.from({ length: 30 }, () =>
        Math.floor(Math.random() * 10000)
      );

      setChartData({
        options: {
          chart: {
            id: "basic-line",
            type: 'line',
          },
          xaxis: {
            categories: fakeCategories,
            min: 0,
            max: 365,
            tickAmount: 10,
          },
          yaxis: {
            title: {
              text: dataType,
            },
            min: 0,
            max: 60,
            tickAmount: 5,
          },
        },
        series: [
          {
            name: "Historical",
            data: fakeHistorical,
          },
          {
            name: "Predicted",
            data: fakePredicted,
          },
        ],
      });
    }
  }, [predictedData, predictionType, dataType, isError]);


  useEffect(() => {
    if (predictedData && dataType === "Analysis (HbA1c)") {
      const historicalData = predictedData.historicalData || [];
      const predictions = predictedData.predictions || [];

      const processDataAnalysis = (data, key) => {
        return data
          .map(item => item ? parseFloat(item[key]) : 0)
          .filter(value => !isNaN(value));
      };

      const convertMonthToFloat = (year, month) => {
        return (parseFloat(year) + (parseFloat(month) / 12)).toFixed(2);
      };

      const convertFloatToYearMonthAnalysis = (floatValue) => {
        const year = Math.floor(floatValue);
        let month = Math.round((floatValue - year) * 12);
        if (month === 0) {
          month = 12;
        }
        return `${year}`;
      };

      const nonZeroAnalysis = historicalData.filter(item => parseFloat(item.Analysis) !== 0);

      console.log("nonZeroAnalysis", nonZeroAnalysis);
      const nonZeroAnalysisYears = nonZeroAnalysis.map(item => parseFloat(item.year));
      console.log("nonZeroAnalysisYears", nonZeroAnalysisYears);


      const firstNonZeroIndex = historicalData.findIndex(item => item !== 0);
      console.log("firstNonZeroIndex", firstNonZeroIndex);
      const startYear = firstNonZeroIndex !== -1 ? historicalData[firstNonZeroIndex]?.year : null;
      const filteredHistoricalData = historicalData.filter(
        (item) => item && item.year >= startYear
      );

      const historicalWeeks = filteredHistoricalData.map(
        (item) => convertMonthToFloat(item.year, item.month)
      );
      const predictedDays = predictions.map((_, index) => index + 1);

      const historicalValues = processDataAnalysis(nonZeroAnalysis, "Analysis");
      const predictedValues = processDataAnalysis(predictions, "Analysis");

      const firstNonZeroHistoricalIndex = historicalWeeks.findIndex(value => value !== 0);
      const firstNonZeroPredictedIndex = predictedValues.findIndex(value => value !== 0);
      const uniqueValues = [...new Set(historicalValues)];
      console.log("uniqueValues", uniqueValues);


      const historicalTrendLine = calculateTrendLine(historicalValues.slice(firstNonZeroHistoricalIndex));
      const predictedTrendLine = calculateTrendLine(predictedValues.slice(firstNonZeroPredictedIndex));

      setChartData({
        options: {
          chart: {
            id: "basic-line",
            type: 'line',
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            title: {
              text: predictionType === 'Predicted' ? "Days" : 'Years',
            },
            labels: {
              formatter: function (val) {
                return convertFloatToYearMonthAnalysis(parseFloat(val));
              }
            },
            categories: predictionType === "Predicted"
              ? predictedDays.slice(firstNonZeroPredictedIndex)
              : nonZeroAnalysisYears,
            min: predictionType === "Predicted" ? 0 : nonZeroAnalysisYears[0],
            max: predictionType === "Predicted" ? predictedDays.length : nonZeroAnalysisYears[nonZeroAnalysisYears.length - 1],
            tickAmount: predictionType === "Predicted" ? 20 : uniqueValues.length - 1,
          },
          colors: ["#5b99a6", "#5b99a6"],
          yaxis: {
            title: {
              text: "mmol/mol",
            },
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
              formatter: (value) => value?.toFixed(0),
            },
          },
          stroke: { width: 4 },
        },
        zoom: {
          enabled: false,
        },
        series: predictionType === "Predicted" ?
          [
            {
              name: "Predicted",
              data: predictedValues.slice(firstNonZeroPredictedIndex),
            },
            {
              name: "Predicted Trend",
              data: predictedTrendLine,
              type: 'line',
              color: '#FF0000',
            },
          ] : [
            {
              name: "Historical",
              data: historicalValues.slice(firstNonZeroHistoricalIndex),
              color: '#5b99a6',
            },
            {
              name: "Historical Trend",
              data: historicalTrendLine,
              type: 'line',
              color: '#FF0000',
            },
          ],
      });
    } else if (isError) {
      const fakeCategories = Array.from(
        { length: 30 },
        (_, i) => `Day ${i + 1}`
      );
      const fakeHistorical = Array.from({ length: 30 }, () =>
        Math.floor(Math.random() * 100)
      );
      const fakePredicted = Array.from({ length: 30 }, () =>
        Math.floor(Math.random() * 100)
      );

      setChartData({
        options: {
          chart: {
            id: "basic-line",
            type: 'line',
          },
          xaxis: {
            categories: fakeCategories,
            min: 0,
            max: 365,
            tickAmount: 10,
          },
          yaxis: {
            title: {
              text: "Analysis (HbA1c)",
            },
            min: 0,
            max: 100,
            tickAmount: 5,
          },
        },
        series: [
          {
            name: "Historical",
            data: fakeHistorical,
          },
          {
            name: "Predicted",
            data: fakePredicted,
          },
        ],
      });
    }
  }, [predictedData, predictionType, dataType, isError]);

  useEffect(() => {
    if (predictedData && dataType === "Relapse") {
      const historicalData = predictedData.historicalData || [];
      const relapsePredictions = predictedData.predictions || [];

      const processRelapseData = (data, key) => {
        return data
          .map(item => item ? parseFloat(item[key]) : 0)
          .filter(value => !isNaN(value));
      };

      const convertFloatToYearMonthRelapse = (floatValue) => {
        const year = Math.floor(floatValue);
        let month = Math.round((floatValue - year) * 12);
        if (month === 0) {
          month = 12;
        }
        return `${year}`;
      };

      const relapseHistoricalValues = processRelapseData(historicalData, "Relapse");
      const relapsePredictedValues = processRelapseData(relapsePredictions, "Relapse");

      const firstNonZeroHistoricalIndex = relapseHistoricalValues.findIndex(value => value !== 0);
      const firstNonZeroPredictedIndex = relapsePredictedValues.findIndex(value => value !== 0);

      const relapseTrendLineHistorical = calculateTrendLine(relapseHistoricalValues.slice(firstNonZeroHistoricalIndex));
      const relapseTrendLinePredicted = calculateTrendLine(relapsePredictedValues.slice(firstNonZeroPredictedIndex));

      const predictedDays = relapsePredictions.map((_, index) => index + 1);

      setChartData({
        options: {
          chart: {
            id: "relapse-line",
            type: 'line',
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            title: {
              text: predictionType === 'Predicted' ? "Days" : 'Years',
            },
            labels: {
              formatter: function (val) {
                return convertFloatToYearMonthRelapse(parseFloat(val));
              }
            },
            categories: predictionType === "Predicted"
              ? predictedDays.slice(firstNonZeroPredictedIndex)
              : Array.from({ length: relapseHistoricalValues.length }, (_, i) => i + 1),
            tickAmount: 10,
          },
          yaxis: {
            title: {
              text: "Relapse",
            },
            min: 0,
            max: 10,
            tickAmount: 5,
            labels: {
              formatter: function (value) {
                return value.toFixed(0);
              },
            },
          },

          colors: ["#5b99a6", "#FF0000"],
          stroke: { width: 4 },
        },
        series: predictionType === "Predicted" ?
          [
            {
              name: "Relapse Predicted",
              data: relapsePredictedValues.slice(firstNonZeroPredictedIndex),
            },
            {
              name: "Predicted Trend",
              data: relapseTrendLinePredicted,
              type: 'line',
              color: '#FF0000',
            },
          ] : [
            {
              name: "Relapse Historical",
              data: relapseHistoricalValues.slice(firstNonZeroHistoricalIndex),
              color: '#5b99a6',
            },
            {
              name: "Historical Trend",
              data: relapseTrendLineHistorical,
              type: 'line',
              color: '#FF0000',
            },
          ],
      });
    }
  }, [predictedData, dataType, predictionType]);

  useEffect(() => {
    setPredictionType("Predicted");
  }, [dataType]);

  const handleThresholdVisibility = () => {
    setShowThreshold(!showThreshold);
  }




  return (
    <div className={styles.container}>
      <ThresholdModal visible={showThreshold} handleClose={setShowThreshold} value={thresholdValue} setValue={setThresoldValue} onSubmit={handleThresholdSubmit} />
      <h1 className={styles.header}>Predicted Data</h1>
      <div className={styles.dropdownContainer}>
        <select
          className={styles.select}
          value={predictionType}
          onChange={(e) => setPredictionType(e.target.value)}
        >
          <option value="Predicted">Predicted</option>
          <option value="Historic (monthly)">Historic (Monthly average)</option>
        </select>
        <select
          className={styles.select}
          value={dataType}
          onChange={(e) => setDataType(e.target.value)}
        >
          <option value="Steps/Day">Steps/Day</option>

          {/* Check if the "Relapse" keyword is present in the data */}
          {predictedData?.historicalData?.some(item => item.hasOwnProperty("Relapse")) &&
            predictedData?.predictions?.some(item => item.hasOwnProperty("Relapse")) ? (
            <>
              <option value="Places visited / Day">Places visited / Day</option>
              <option value="Relapse">Relapse</option>
            </>
          ) : (
            <option value="Analysis (HbA1c)">HbA1c</option>
          )}
        </select>



      </div>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}></div>
        </div>
      ) : isError ? (
        <div>Error loading data</div>
      ) : (
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={400}
          key={`${predictionType}`}
        />

      )}
      <div className={styles.infoBoxContainer}>
        {predictionType === "Predicted" && (
          <div className={styles.infoBox}>
            Predicted From: Age, Diagnosis, Gender, Disease Duration, Steps Missing, Time on Therapy, Time to Therapy, Age Onset, Diabetes_Type_2, Analysis
          </div>
        )}

        <div className={styles.infoBox2}>
          <div>
            <div>Steps: <span style={{ color: '#5b99a6' }}>Blue</span></div>
            <div>Trend:  <span style={{ color: 'red' }}>Red</span> </div>
            {predictionType === "Predicted" && (<div>R²:  <span style={{ color: 'red', marginLeft: '15px' }}>{(predictedData?.r2 * 100).toFixed(2)}</span> </div>)}
          </div>
          <span style={{ paddingTop: '0.5rem', cursor: 'pointer' }}>
            Threshold: {user?.threshold? user?.threshold:'not set'}
            <AiFillAlert color="red" size={20} style={{ marginTop: '-5px', marginLeft: '10px' }} onClick={handleThresholdVisibility} />
            {/* {notificationSent ? <span style={{ color: 'green', marginLeft: '15px' }}>Notification Sent</span> : null} */}

          </span>

        </div >

      </div >
      <div className="graphButtons">
        {user?.lastValidatedBy?.includes(loggedInUser?.id) ? <CustomButton
          color={'white'}
          label={'Request Data Update'}
          onClick={() => {

            const vts = {
              sellerId: user?._id ? user._id : user?.id,
            }
            console.log("VTS: ", vts)
            notifySeller(vts)

          }}
        /> : user?._id === loggedInUser?.id ? <button
          style={styles.updateButton}
          onClick={() => toast.success("Please update it through your mobile phone")}
        >
          Update
        </button> : null}
        <p><b>Last updated : </b>
          {new Date(user?.lastFetchedSteps).toDateString()} ({new Date(user?.lastFetchedSteps).toLocaleTimeString()})
        </p>
      </div>

    </div >
  );
};

export default UserPrediction;
