import React, { useState, useRef, useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import CustomButton from "../../components/custombutton/CustomButton";
import { useSendPinMutation, useVerifyPinMutation } from "../../store/api"; // Assuming you have an OTP verification mutation
import { useNavigate } from "react-router-dom";
import RouteNames from "../../routes/RouteNames";
import toast from "react-hot-toast";
import "./Authentication.scss";
import { useSelector } from "react-redux";


const PINConfirmModal = ({ show, onHide, userId, otpType }) => {
    console.log("otpType", otpType);
    const user = useSelector((state) => state?.auth?.user?.user);
    const [sendPin, {data, isSuccess, isError, isLoading, error}] =useSendPinMutation();
const [verifyPin] =useVerifyPinMutation();
const [pin, setPin] = useState(["", "", "", ""]);
const inputRefs = useRef([]);
const navigation = useNavigate();
    useEffect(() => {
        if (isSuccess) {
            // setAuthenticationStatus('authenticated'); 
      localStorage.setItem("twoFAliberdat", 'authenticated');

     toast.success("Pin saved")
      setTimeout(() => { 
              navigation(RouteNames.Home.route);
            }, 200);
            
          
        } else if (isError) {
          console.log('ERROR', error);
        }
      }, [error, isError, isSuccess]);
      useEffect(() => {
        if (show) {
            // Find the first empty box and focus it
            const firstEmptyIndex = pin.findIndex((digit) => digit === "");
            if (firstEmptyIndex !== -1 && inputRefs.current[firstEmptyIndex]) {
                inputRefs.current[firstEmptyIndex].focus();
            }
        }
    }, [show, pin]);

    // const [verifyOtp, { isSuccess, isError, isLoading }] = useVerifyOTPMutation();

    // Handle input change and auto-move to next
    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value)) {  // Only allow digits
            const newOtp = [...pin];
            newOtp[index] = value;  // Set value in the correct index
            setPin(newOtp);

            // Automatically focus on the next input
            if (index < 3) {
                inputRefs.current[index + 1].focus();
            }
        }
    };
 
    // Handle backspace to move focus to previous input
   // Handle backspace to remove digit and move focus to previous input
const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
        const newOtp = [...pin];
        
        if (pin[index] === "" && index > 0) {
            inputRefs.current[index - 1].focus();
        } else {
            // Remove the current digit
            newOtp[index] = "";
            setPin(newOtp);
        }
    }
};


    // Submit OTP once all fields are filled
    const handleSubmit = async () => {
        const otpCode = pin.join("");  // Join OTP digits into a single string

        if (otpCode.length === 4) {
            try {
                if(otpType){
                    if(otpType===otpCode){
                        
                        const vts = {
                            userId: user?.id,
                            pin: Number(otpCode),
                          };
                          sendPin(vts)
                    }
                    else{
                        toast.error("Pin mis-match")
                    }
                }
                else{
                   
                    const vts = {
                        userId: user?.id,
                        pin: Number(otpCode),
                      };
                    const vp=await verifyPin(vts)
                    if(vp?.data){
                        localStorage.setItem("twoFAliberdat", 'authenticated');
                      
                          setTimeout(() => { 
                            navigation(RouteNames.Home.route);
                          }, 200);
                        
                      
                    }
                    else{
                        toast.error("Please enter correct pin")
                    }
                }
                
               
            } catch (error) {
                toast.error("Pin verification failed!");
            }
        } else {
            toast.error("Please enter a valid 4-digit Pin.");
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered className="otp-modal">
            <Modal.Header closeButton>
                <Modal.Title>{otpType?'Re-enter PIN':"Enter PIN"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="otp-instructions">
                {otpType? "Re-enter your secret pin for confirmation.":"Enter your 4-digit secret pin"}
                </p>

                <div className="otp-form">
                    <Row className="otp-inputs" style={{width:'100%'}}>
                        {pin.map((data, index) => (
                            <Col key={index} xs={2} className="otp-input-wrapper">
                                <Form.Control
                                    type="text"
                                    maxLength="1"  // Only allow one digit per input
                                    value={data}
                                    onChange={(e) => handleChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(el) => inputRefs.current[index] = el}  // Store the input reference
                                    className="otp-input"
                                />
                            </Col>
                        ))}
                    </Row>
                    <CustomButton
                        label="Verify"
                        color={"#fff"}
                        textcolor={"#ffffff"}
                        backgroundColor={"#5B99A6"}
                        width="100%"
                        // isLoading={isLoading}
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PINConfirmModal;
