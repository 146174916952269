import React from "react";
import { Card } from "react-bootstrap";
import "./style.scss";

const TransactionCard = ({ transaction }) => {
    const formattedDate = new Date(transaction.createdAt).toLocaleDateString();
    const isCredited = transaction.transactionType === "top_up";

    return (
        <Card className={`transaction-card mb-4 ${isCredited ? 'credited' : 'withdrawal'}`}>
            <Card.Body>
                <div className="transaction-header">
                    <div className="transaction-icon">

                        <span >
                            {isCredited ? (

                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#5b99a6" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-left-right"><path d="M8 3 4 7l4 4" /><path d="M4 7h16" /><path d="m16 21 4-4-4-4" /><path d="M20 17H4" /></svg>

                            ) : (

                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#ff0000" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right-left"><path d="m16 3 4 4-4 4" /><path d="M20 7H4" /><path d="m8 21-4-4 4-4" /><path d="M4 17h16" /></svg>

                            )}
                        </span>
                    </div>
                    <div>
                        <h5 className="transaction-type">{isCredited ? "Credited" : "Withdrawal"}</h5>
                        <p className="transaction-date">{formattedDate}</p>
                    </div>
                </div>
                <div className="transaction-details">
                    <p><strong>Amount:</strong> €{transaction.amount}</p>
                    <p><strong>Sender:</strong> {transaction.sender}</p>
                    <p><strong>Receiver:</strong> {transaction.receiver}</p>
                </div>
            </Card.Body>
        </Card>
    );
};

export default TransactionCard;
