import React, { useEffect } from "react";
import "./style.scss";
import { Button, Container, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useGetUserQuery } from "../../store/api";
import RouteNames from "../../routes/RouteNames";

const MyLiberDat = () => {
  const userData = useSelector((state) => state?.auth?.user?.user?.id);
  const {
    data: DataOfLoggedinUser,
    isSuccess: isSuccessDataUser,
    refetch: ref,
  } = useGetUserQuery(userData);
  // console.log("DataOfLoggedinUser", DataOfLoggedinUser);
  useEffect(() => {
    if (isSuccessDataUser) {
    }
  }, [isSuccessDataUser, DataOfLoggedinUser]);
  const user = useSelector((state) => state.auth?.user?.user);
  const userRole = useSelector((state) => state.auth?.user?.user?.role);
  const isBuyer = userRole === "buyer"; // Replace with your first condition
  const isSeller = userRole === "seller";
  const buttonLabels = [
    {
      // id: 1,
      // heading: "My Data",
      // link: "/buyer-info",
      id: 1,
      heading: isBuyer ? "My Data" : "My Data",
      link: isBuyer ? "/buyer-info" : "/general-info",
    },
    {
      id: 2,
      heading: "Favorites",
      // link: "/favorites",
    },
    // {
    //   id: 3,
    //   heading: "Searches",
    //   link: "/searches",
    // },
    { id: 4, heading: "My Requests", link: "/mybids" },
    {
      id: 5, heading: "Requests",
      link: "/my-requests"
    },
    {
      id: 10, heading: "Predict",
      link: `/user-prediction`
    },
    { id: 6, heading: "Permission", link: "/datapage" },
    {
      id: 7,
      heading: "Settings", link: "/delete-by-email"
    },
    {
      id: 8, heading: "Wallet",
      link: "/wallet",
    },
    {
      id: 9, heading: "Privacy policy",
      link: "/privacy-policy",
    },
  ];
  const filteredData = buttonLabels.filter((item, index) => {
    if ((item.id === 5 || item.id === 6 || item?.id == 9 || item?.id == 10) && isBuyer) {
      return false; // Skip the 5th item
    }
    if ((item.id === 8 || item.id === 6) && isSeller) {
      return false; // Skip the 5th item
    }
    if (item.id === 4 && isSeller) {
      return false; // Skip the 5th item
    }




    // if (index === 6 && isBuyer) {
    //   return false; // Skip the 5th item
    // }
    // if ((index === 4 && isSeller) || (index === 3 && isSeller)) {
    //   return false; // Skip the 4th item
    // }
    return true; // Keep all other items
  });
  return (
    <>
      <main id="main">
        <section className="datalist">
          <div className="container">
            {/* {user ? <h1>{user.username}</h1> : <h1>My LiberDat</h1>} */}
            <h1>My LiberDat</h1>
            <ListGroup as="ul" className="listitems">
              {filteredData.map((label, index) => (
                <>
                  <ListGroup.Item
                    key={index}
                    as="li"
                    style={{
                      marginBottom:
                        label.id === 3 || label.id === 6 ? "30px" : "10px",
                    }}
                  >
                    <Link
                      to={isBuyer || isSeller ? label.link : null}
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                      onClick={!isBuyer && !isSeller ? () => toast.error("Login First") : null}
                      className="d-flex justify-content-between align-items-center"
                    >
                      {label.heading}
                      <i
                        style={{
                          position: "relative",
                          left: "5px",
                          top: "1px",
                          color: "green",
                        }}
                        className="fas fa-chevron-right"
                      ></i>
                    </Link>
                  </ListGroup.Item>
                </>
              ))}
            </ListGroup>
          </div>
        </section>
      </main>
    </>
  );
};

export default React.memo(MyLiberDat);
