import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
// import { useSelector } from "react-redux";
import './customizemodel.scss';
import { useSendCapturePaymentIdMutation, useSendPaymentStripeIdMutation } from "../../store/api";
// import { useAssignBidMutation, useSendPaymentStripeIdMutation } from "../../store/api";
export const StripeWalletPayment = React.memo(({show,handleClose,paymentDoc,refetch,userId}) => {
    const [
        sendCapture 
      ] = useSendCapturePaymentIdMutation();
  const [sendStripeId] = useSendPaymentStripeIdMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const stripe = useStripe();
  const elements = useElements();



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    console.log("SUCCESSFULLL",elements)
    const  {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/wallet?amount=${paymentDoc?.amount}&stripeId=${paymentDoc?.paymentIntentId}&customerId=${paymentDoc?.customerId}`,
        payment_method_data:{
            allow_redisplay:'always',
        }    
      },
      redirect: 'if_required',
    });
    if (error) {
      console.log("ERRRORORR",error);
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } 
    else{
      setTimeout(() => {
        handleClose();
      }, 500);
      
    //   console.log("TOAST SUCCESS",data)
    const { data } = await sendStripeId(paymentDoc.paymentIntentId);
    const vts={
      customerId:paymentDoc?.customerId,
paymentMethod:data?.type,
amount:paymentDoc?.amount,
    }
      const r=await sendCapture(vts)
      toast.success(`Adding balance to your wallet!`);

      console.log("CAPTURE PAYMENT",r)
        refetch(userId)
        // setTimeout(() => { 
          window.location.reload();
        //  }, 100)
        
    }

    setIsLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="md" style={{marginTop:'5vh',marginBottom:'7vh'}} >
      <Modal.Header closeButton>
        <Modal.Title>Payment to wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <form id="payment-form" onSubmit={handleSubmit} style={{ height: 'auto' }}>
            {/* <LinkAuthenticationElement id="link-authentication-element" /> */}
            <PaymentElement id="payment-element" />
            <button disabled={isLoading || !stripe || !elements} id="submit" style={{ marginTop: '20px',width:'100%',borderRadius:'7px' }}>
              <span id="button-text">
                {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
              </span>
            </button>
            {message && <div id="payment-message" style={{color:'red',textAlign:'center'}}>{message}</div>}
          </form>
     
    {/* </div> */}
    </Modal.Body>
    </Modal>
  );
});
