import React, { useCallback, useState } from "react";

import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../routes/RouteNames";
import "./style.scss";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosInformationCircle } from "react-icons/io";
import { HiOutlineUsers } from "react-icons/hi2";
import { useSelector } from "react-redux";

const Customcard = ({ bid }) => {
  // if (bid.assignedUsers) {
  //   console.log("BIDDDDD", bid.assignedUsers);
  // }
  const userId = useSelector(state => state.auth?.user?.user?.id);
  // console.log(userId)


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const navigate = useNavigate();
  const handleBidUser = (value) => {
    console.log("ID?????", value);
    navigate(RouteNames.ProfileInfo.route, {
      state: { value, bidPage: true },
    });
  };
  const extractLastWord = useCallback((str) => {
    const words = str?.split('_');
    const lastThree = words?.slice(-1);
    return lastThree?.join(' ') ?? '';
  }, []);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openDescriptionModal = () => {
    setIsDescriptionModalOpen(true);
  };

  const closeDescriptionModal = () => {
    setIsDescriptionModalOpen(false);
  };

  const handlePredict = () => {
    const user = bid;
    navigate(RouteNames.UserPrediction.route, {
      state: { user },
    });
  };

  return (
    <Card className="card">
      <Card.Body className="card-body">
        {/* <p className="bidtitle">{bid?.diagnosis ? bid?.diagnosis : 'Untitled'}</p> */}
        <p className="bidtitle">
          {bid?.diagnosis ? bid?.diagnosis : bid?.episode}
        </p>
        <p className="card-text">
          <h6>
            <HiOutlineUsers size={25} />
          </h6>{" "}
          <p
            style={{ cursor: "pointer", color: "#5b99a6" }}
            onClick={openModal}
          >
            {bid?.assignedUsers?.length}
          </p>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            ariaHideApp={false}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                zIndex: 1000,
              },
              content: {
                width: "40vw",
                margin: "auto",
                height: "55vh",
                padding: "43px",
                zIndex: 9999,
              },
            }}
          >
            <AiOutlineCloseCircle onClick={closeModal} className="closearrow" />
            <div className="assignusers">
              <h2>Assigned User</h2>
              {bid?.assignedUsers?.length > 0 ? (
                <ul>
                  {bid.assignedUsers.map((user, index) => {
                    // console.log("USER", user?.sellerRef?.lastValidatedBy);
                    return (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <span
                          // onClick={() => handleBidUser(user?.sellerRef?.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {user?.sellerRef?.username || 'NaN'}
                        </span>

                        {user?.sellerRef?.lastValidatedBy?.includes(userId) && (
                          <button
                            onClick={handlePredict}
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "#5b99a6",
                              color: "white",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                          >
                            Predict
                          </button>)}
                      </li>
                    )
                  })}
                </ul>
              ) : (
                <span style={{ fontWeight: "bold", marginTop: "10vh" }}>
                  "No Assigned Users"
                </span>
              )}
            </div>

          </Modal>
          {/* Description Modal */}
          <Modal
            isOpen={isDescriptionModalOpen}
            onRequestClose={closeDescriptionModal}
            ariaHideApp={false}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                zIndex: 1000,
              },
              content: {
                top: "50%", // Centered vertically
                left: "50%", // Centered horizontally
                transform: "translate(-50%, -50%)",
                border: "none",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                zIndex: 9999,
              },
            }}
          >
            <div className="description-content">
              <h2>Information</h2>
              <p>{bid?.description}</p>
            </div>
          </Modal>
        </p>
        <p className="card-text">
          <h6>Data:</h6> <p>{bid?.dataItem?.specifiedValues?.reduce((acc, click) => {
            if (click?.value?.includes?.('Therapies') || click?.value?.includes?.('Apple') || click?.value?.includes?.('Google') || click?.value?.includes?.('Questionnaire') || click?.value?.includes?.('Location')) {
              if (acc.length > 0) {
                if (acc?.includes?.('Therapies') && click?.value?.includes?.('Therapies')) {
                  return acc;
                }
                else {
                  return acc + ', ' + extractLastWord(click?.value);

                }
              } else {
                if (click?.value?.includes?.('Therapies')) {
                  return extractLastWord('Therapies');
                }
                else {
                  return extractLastWord(click?.value);

                }

              }
            } else {
              return acc;
            }
          }, '')}</p>
        </p>
        <p className="card-text">
          <h6>Price</h6>{" "}
          <p>
            {bid?.price?.amount}
            {bid?.price?.currency}
          </p>
        </p>
        <p className="card-text"></p>
        {/*        
        <p className="card-text">
          <h6>Duration:</h6>{' '}
          <p>
            {bid?.duration?.number} &nbsp;
            {bid?.duration?.interval}
          </p>
        </p> */}
        <p className="card-text">
          <h6>Date(Created Request): </h6>{" "}
          <p>
            {bid?.createdAt
              ? new Date(bid?.createdAt).toLocaleDateString()
              : ""}
          </p>
        </p>
        {/* <p className="card-text">
          <h6>Source: </h6>{' '}
          <p>
            {bid?.source?.mobile && bid?.source?.mobile.length > 0
              ? bid?.source?.mobile
              : bid?.source?.questionnaire}
          </p>
        </p> */}
        <p className="card-text">
          <h6>Information: </h6>{" "}
          <p>
            <IoIosInformationCircle
              size={25}
              onClick={openDescriptionModal}
              style={{ cursor: "pointer", color: "#5b99a6" }}
            />
          </p>
        </p>
      </Card.Body>
    </Card>
  );
};

Customcard.propTypes = {
  bid: PropTypes.object.isRequired, // You may need to adjust the PropTypes accordingly
};

export default Customcard;
